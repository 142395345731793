import Vue from 'vue'
import VueRouter from 'vue-router'
import pageConnexion from '../views/pageConnexion';

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'connexion',
		component: pageConnexion
	},
	{
		path: '/retrouver-identifiants',
		name: 'identifiants',
		component: () => import(/* webpackChunkName: "identifiants" */ '../views/pageIdentifiants')
	},
	{
		path: '/activation/:dataCommande?',
		name: 'activation',
		component: () => import(/* webpackChunkName: "activation" */ '../views/pageActivation'),
		props: true
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
