<template>
	<div class="row h-100 align-items-center">
		<div class="col-10 col-lg-8 col-xl-6 m-auto">
			<div class="card fieldset-accueil">
				<div class="card-header">
					<h1 class="text-center titre-accueil">Accédez à votre profil Qualitéval</h1>
				</div>
				<div class="card-body d-none">
					<p class="card-text">
						Qualitéval est actuellement en maintenance programmée.<br />
						Merci de patienter jusqu&rsquo;au retour du service.
					</p>
				</div>
				<div class="card-body" id="connexionBody">
					<transition name="fade">
						<div v-if="alerte.message !== ''" class="alert" :class="['alert-' + alerte.type]" v-html="alerte.message"></div>
					</transition>
					<form method="post" action="connexion" id="formConnexion" v-show="redirect === false" @submit.prevent="connexion">
						<div class="form-group row">
							<label for="login" class="col-xl-3 col-lg-4 col-form-label textes-accueil text-lg-right">Identifiant</label>
							<div class="col">
								<input type="text" class="form-control bord-input" name="login" id="login" v-model="login" placeholder="Identifiant ou courriel" required>
							</div>
						</div>
						<div class="form-group row">
							<label for="mdp" class="col-xl-3 col-lg-4 col-form-label textes-accueil text-lg-right">Mot de passe</label>
							<div class="col">
								<div class="input-group">
									<input :type="passwordType" class="form-control bord-input" name="mdp" id="mdp" v-model="mdp" placeholder="Mot de passe" required>
									<div class="input-group-append">
										<button class="btn btn-secondary" type="button" title="Afficher/Masquer le mot de passe" @click="passwordType = (passwordType === 'password') ? 'text' : 'password'">
											<i class="fas fa-eye">👁️</i>
										</button>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group text-center">
							<button type="submit" class="btn btn-secondary" :disabled="loading">
								<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading"></span>
								Se connecter
							</button>
						</div>
					</form>
					<form method="post" :action="userUrl" id="formRedirect" v-show="redirect">
						<input type="hidden" name="login" v-model="login">
						<input type="hidden" name="mdp" v-model="mdp">
					</form>
				</div>
				<div class="card-footer">
					<p class="text-center">
						<router-link to="/retrouver-identifiants" class="textes-accueil"><em>Vous avez oublié vos identifiants ?</em></router-link>
					</p>
				</div>
			</div>
			<p class="text-center mt-4">
				Vous n&rsquo;êtes pas encore utilisateur ?<br />
				Pour découvrir et acheter le logiciel Qualitéval, <a href="https://www.qualiteval.fr" class="texte-bleu">cliquez ici</a>.
			</p>
		</div>
	</div>
</template>

<script>
import 'whatwg-fetch';

export default {
	data () {
		return {
			login: '',
			mdp: '',
			passwordType: 'password',
			loading: false,
			redirect: false,
			alerte: {
				type: '',
				message: ''
			},
			userUrl: ''
		}
	},
	methods: {
		connexion () {
			this.loading = true;
			this.alerte.message = '';

			const formDatas = new FormData(this.$el.querySelector('#formConnexion'));
			fetch('/connexion', {
				method: 'POST',
				body: formDatas
			}).then(data => {
				this.loading = false;
				if (data.status === 204) {
					// alert('Identifiant introuvable');
					return Promise.resolve({
						oldLog: false,
						message: 'Identifiant ou mot de passe incorrect !',
						nom: ''
					});
				} else if (data.status === 401) {
					// alert('Identifiant incorrects');
					return Promise.resolve({
						oldLog: false,
						usePseudo: true,
						message: 'Les informations de connexion sont incorrectes.',
						nom: ''
					});
				} else {
					return data.json();
				}
			}).then(user => {
				if (typeof (user.oldLog) !== 'undefined') {
					if (user.oldLog) {
						this.redirect = true;
						this.alerte.type = 'success';
						this.alerte.message = 'Connexion réussie, redirection en cours...';
						// Envoi comme si on était le formulaire de connexion pour rediriger vers la normalisation
						this.userUrl = user.base + user.url;
						this.$nextTick(() => {
							// On attend le prochain tick pour être sur d'avoir la bonne url d'action
							this.$el.querySelector('#formRedirect').submit();
						});
					} else {
						if (user.usePseudo === false) {
							this.alerte.type = 'warning';
							this.alerte.message = 'Vous avez essayé d&rsquo;utiliser votre courriel pour vous connecter, alors qu&rsquo;un identifiant est défini.<br />Désormais pour vous connecter, veuillez utiliser votre identifiant (<strong>' + user.nom + '</strong>)';
							this.login = user.nom;
						} else {
							if (user.nom) {
								// Connexion ok du 1er coup
								this.redirect = true;
								this.alerte.type = 'success';
								this.alerte.message = 'Connexion réussie, <strong>' + user.nom + '</strong>, redirection en cours...';
								// Précharger la connexion
								fetch(user.base + '/connexion/' + user.url, { credentials: 'include', mode: 'no-cors' })
									.then(res => res.text())
									.then(pageConnexion => {
										if (pageConnexion.search('data-token') !== -1) {
											// Connexion OK, rediriger sur l'accueil si pas de referer
											const referer = new URL(document.location).searchParams.get('retour');
											if (referer !== null) {
												if (referer.search(user.base) !== -1) {
													window.location.href = referer;
												} else {
													// Le connecter au module concerné aussi
													fetch(referer.replace(/qualiteval\.fr.+/, 'qualiteval.fr') + '/connexion/' + user.url, { credentials: 'include', mode: 'no-cors' })
														.then(res => res.text())
														.then(pageConnexion => {
															if (pageConnexion.search('data-token') !== -1) {
																// Connexion OK, rediriger sur l'url
																window.location.href = referer;
															} else {
																// Réessai client
																window.location.href = referer.replace(/qualiteval\.fr.+/, 'qualiteval.fr') + '/connexion/' + user.url;
															}
														})
														.catch(() => {
															this.alerte.type = 'danger';
															this.alerte.message = 'Erreur lors de la redirection';
														});
												}
											} else {
												window.location.href = user.base;
											}
										} else {
											// Réessai client
											window.location.href = user.base + '/connexion/' + user.url;
										}
									})
									.catch(() => {
										this.alerte.type = 'danger';
										this.alerte.message = 'Erreur lors de la redirection';
									});
							} else {
								this.alerte.type = 'danger';
								this.alerte.message = user.message;
							}
						}
					}
				} else {
					this.redirect = true;
					this.alerte.type = 'success';
					this.alerte.message = 'Votre demande a bien été prise en compte.' +
						'<br /><br />' +
						'Un courriel pour la connexion de <strong>' + user.nom + '</strong> dans l&rsquo;établissement &laquo; ' + user.nom_etablissement + ' &raquo; a été envoyé.' +
						'<br /><br />' +
						'Si vous ne recevez pas de courriel d&rsquo;ici quelques minutes, <a href="/retrouver-identifiants">cliquez ici pour effectuer une nouvelle tentative</a>.';
				}
			}).catch(err => {
				this.loading = false;
				this.alerte.type = 'danger';
				this.alerte.message = 'Erreur serveur';
				if (err) {
					alert(err);
				}
				// console.warn(err);
			});
		}
	}
}
</script>
